import React, { useMemo } from 'react';
import { useRoutesContext } from '../../routes/context/RoutesContext';
// import { useAuthContext } from '../../store/hooks/useAuthContext';
import { SidebarMenu } from './../../components';

export const SidebarMiddleNav = () => {
  const pages = useRoutesContext();
  const _pages = useMemo(() => {
    return pages.filter((page) =>
      Object.prototype.hasOwnProperty.call(page, 'label')
    );
  }, [pages]);
  // const [user] = useAuthContext();
  return (
    <SidebarMenu>
      {_pages.map((page, index) => {
        const icon = page.icon ?? <i className="fa fa-fw fa-circle-o" />;
        if (Object.prototype.hasOwnProperty.call(page, 'rows')) {
          return (
            <SidebarMenu.Item title={page.label} icon={icon} key={index}>
              {page.rows.map((row) => {
                return (
                  <SidebarMenu.Item
                    title={row.label}
                    to={row.path}
                    key={row.path}
                  />
                );
              })}
            </SidebarMenu.Item>
          );
        }
        return (
          <SidebarMenu.Item
            title={page.label}
            icon={icon}
            to={page.path}
            key={page.path}
          />
        );
      })}
    </SidebarMenu>
  );
};
