import PropTypes from 'prop-types';
import React from 'react';

export default class ErrorBoundary extends React.Component {
  static propTypes = {
    fallback: PropTypes.any,
    children: PropTypes.any,
  };
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  refresh = () => {
    location.reload();
  };

  render() {
    if (this.state.hasError === true) {
      return <this.props.fallback refresh={this.refresh} />;
    }

    return this.props.children;
  }
}
