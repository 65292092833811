import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { ToastContainer } from 'react-toastify';
import { QueryClientProvider } from '@tanstack/react-query';

import 'react-toastify/dist/ReactToastify.css';

import App from '@app/index';
import { queryClient } from './@core/utils/query-client';
import ErrorBoundary from './@core/components/ErrorBoundary';
import { Button } from 'reactstrap';

function Error500Page({ refresh }) {
  return (
    <div
      style={{ width: '100vw', height: '100vh' }}
      className="d-flex flex-column justify-content-center align-items-center p-4"
    >
      <h1 className="bold">
        <span className="text-primary">500</span> 오류
      </h1>
      <p className="text-center">
        예기치 못한 오류가 발생하였습니다.
        <br />
        새로고침을 하거나, 메인 페이지로 이동해주세요.
      </p>
      <Button onClick={refresh} color="primary" className="mt-4">
        새로고침
      </Button>
    </div>
  );
}
Error500Page.propTypes = {
  refresh: PropTypes.func,
};

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <ErrorBoundary fallback={Error500Page}>
      <App />
      <ToastContainer position="bottom-left" />
    </ErrorBoundary>
  </QueryClientProvider>,
  document.getElementById('root')
);
