import React from 'react';
import PropTypes from 'prop-types';
import { withPageConfig } from './../Layout';

const SidebarTrigger = (props) => {
  return (
    <div
      className="sidebar-trigger"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        props.pageConfig.toggleSidebar();
      }}
    >
      {props.children}
    </div>
  );
};
SidebarTrigger.propTypes = {
  children: PropTypes.node,
  pageConfig: PropTypes.object,
};
SidebarTrigger.defaultProps = {
  children: <i className="fa fa-bars fa-fw"></i>,
};

const cfgSidebarTrigger = withPageConfig(SidebarTrigger);

export { cfgSidebarTrigger as SidebarTrigger };
