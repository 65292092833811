import React from 'react';
import { Navbar, Nav, NavItem, SidebarTrigger } from './../../components';

import { NavbarUser } from './NavbarUser';
import { Link } from 'react-router-dom';
import { LogoThemed } from '../../routes/components/LogoThemed/LogoThemed';

export function DefaultNavbar() {
  return (
    <Navbar light expand="xs" fluid>
      <Nav navbar>
        <NavItem className="mr-3">
          <SidebarTrigger />
        </NavItem>
        <NavItem className="d-lg-none">
          <Link to="/">
            <LogoThemed height="24px" />
          </Link>
        </NavItem>
      </Nav>
      <Nav navbar className="ml-auto">
        <NavbarUser />
      </Nav>
    </Navbar>
  );
}
