import React, { lazy } from 'react';

const Login = lazy(() => import('./Login'));
const ForgotPassword = lazy(() => import('./ForgotPassword'));

const Staff = lazy(() => import('./Staff'));
const Me = lazy(() => import('./Me'));
const BandBanner = lazy(() => import('./BandBanner'));
const Brochure = lazy(() => import('./Brochure'));
const Portfolio = lazy(() => import('./Portfolio'));
const Project = lazy(() => import('./Project'));
const Maintenance = lazy(() => import('./Maintenance'));
const KakaoSendHistory = lazy(() => import('./KakaoSendHistory'));
const KakaoAlramTalk = lazy(() => import('./KakaoAlramTalk'));

export const pages = [
  {
    authorized: false,
    path: '/login',
    component: Login,
    exact: true,
  },
  {
    authorized: false,
    path: '/forgot-password',
    component: ForgotPassword,
    exact: true,
  },
  {
    label: '콘텐츠 관리',
    rows: [
      {
        authorized: true,
        path: '/band-banner',
        component: BandBanner,
        label: '띠배너',
      },
      {
        authorized: true,
        path: '/brochure',
        component: Brochure,
        label: '회사소개서',
      },
    ],
  },
  {
    label: '포트폴리오 관리',
    path: '/portfolio',
    component: Portfolio,
    authorized: true,
  },
  {
    label: '문의 관리',
    rows: [
      {
        label: '신규 프로젝트',
        path: '/project',
        component: Project,
        authorized: true,
      },
      {
        label: '하자보수',
        path: '/maintenance',
        component: Maintenance,
        authorized: true,
      },
    ],
  },
  {
    label: '카카오알림톡 관리',
    rows: [
      {
        label: '카카오알림톡 발송',
        path: '/kakao-send',
        component: KakaoAlramTalk,
        authorized: true,
      },
      {
        label: '카카오알림톡 이력',
        path: '/kakao-send-history',
        component: KakaoSendHistory,
        authorized: true,
      },
    ],
  },
  {
    label: '관리자 계정 관리',
    rows: [
      {
        authorized: true,
        path: '/staff',
        component: Staff,
        label: '관리자 목록',
      },
      {
        authorized: true,
        path: '/me',
        component: Me,
        label: '내 계정',
        icon: <i className="fa-feed fa-fw fa" />,
      },
    ],
  },
];
