import React from 'react';
import PropTypes from 'prop-types';

import { Layout, ThemeProvider } from '@core/components';

import '../../styles/bootstrap.scss';
import '../../styles/main.scss';
import '../../styles/plugins/plugins.scss';
import '../../styles/plugins/plugins.css';

import { RoutedNavbars, RoutedSidebars } from '@core/routes';
import { useAuthContext } from '../store/hooks/useAuthContext';

const favIcons = [
  {
    rel: 'icon',
    type: 'image/x-icon',
    href: require('../../images/favicons/favicon.ico'),
  },
  {
    rel: 'apple-touch-icon',
    sizes: '180x180',
    href: require('../../images/favicons/apple-touch-icon.png'),
  },
  {
    rel: 'icon',
    type: 'image/png',
    sizes: '32x32',
    href: require('../../images/favicons/favicon-32x32.png'),
  },
  {
    rel: 'icon',
    type: 'image/png',
    sizes: '16x16',
    href: require('../../images/favicons/favicon-16x16.png'),
  },
];

const AppLayout = ({ children }) => {
  const [authState] = useAuthContext();

  if (authState.isLogin) {
    return (
      <ThemeProvider initialStyle="light" initialColor="primary">
        <Layout sidebarSlim favIcons={favIcons}>
          {/* --------- Navbar ----------- */}
          <Layout.Navbar>
            <RoutedNavbars />
          </Layout.Navbar>
          {/* -------- Sidebar ------------*/}
          <Layout.Sidebar>
            <RoutedSidebars />
          </Layout.Sidebar>
          {/* -------- Content ------------*/}
          <Layout.Content>{children}</Layout.Content>
        </Layout>
      </ThemeProvider>
    );
  } else {
    return <>{children}</>;
  }
};

AppLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppLayout;
