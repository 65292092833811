import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 0;
  position: relative;
  padding-top: 24px;
`;

const LayoutContent = (props) => {
  return <Wrapper className="layout__content">{props.children}</Wrapper>;
};

LayoutContent.propTypes = {
  children: PropTypes.node,
};
LayoutContent.layoutPartName = 'content';

export { LayoutContent };
