import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import AppLayout from '@core/layout/default';
import { useAuthContext } from '@core/store/hooks/useAuthContext';
import axios from 'axios';
import { authActionCreator } from '@core/store/actions/authActions';
import { pages } from './pages';
import { RoutesProvider } from '../@core/routes/context/RoutesContext';
import AuthProvider from '../@core/store/AuthProvider';
import { RouteGenerator } from '../@core/routes';

function Root() {
  const [isReady, setIsReady] = useState(false);
  const [, authDispatch] = useAuthContext();

  // 자동로그인
  useEffect(() => {
    let isMount = true;
    const controller = new AbortController();
    async function persistLogin() {
      try {
        const response = await axios.get('/auth/access-token', {
          signal: controller.signal,
        });
        const { access_token } = response.data;
        const meResponse = await axios.get('/auth/me', {
          headers: { Authorization: `Bearer ${access_token}` },
          signal: controller.signal,
        });
        const data = meResponse.data;
        const loginInfo = {
          access_token,
          ...data,
        };
        if (isMount === true) {
          authDispatch(authActionCreator.Login(loginInfo));
        }
      } catch (e) {
        // NOTHING
      } finally {
        setIsReady(true);
      }
    }

    persistLogin();

    return () => {
      isMount = false;
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isReady) {
    return null;
  }

  return (
    <AppLayout>
      <RouteGenerator />
    </AppLayout>
  );
}

const AppClient = () => {
  return (
    <RoutesProvider value={pages}>
      <BrowserRouter>
        <AuthProvider>
          <Root />
        </AuthProvider>
      </BrowserRouter>
    </RoutesProvider>
  );
};

export default AppClient;
