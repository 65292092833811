import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import logoImage from '@images/logos/Logo.png';

const LogoThemed = ({ className, ...otherProps }) => (
  <img
    src={logoImage}
    style={{
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
    }}
    className={classNames('d-block', className)}
    {...otherProps}
  />
);
LogoThemed.propTypes = {
  className: PropTypes.string,
};

export { LogoThemed };
