import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

export function useDefaultModal() {
  const [modalState, setModalState] = useState({
    isOpen: false,
    title: '',
    message: '',
  });
  const openModal = (title, message) => {
    setModalState({ title, message, isOpen: true });
  };
  const closeModal = () => {
    setModalState((prev) => ({ ...prev, isOpen: false }));
  };

  return {
    props: {
      headerMessage: modalState.title,
      bodyMessage: modalState.message,
      isOpen: modalState.isOpen,
      closeFunc: closeModal,
    },
    openModal,
    closeModal,
  };
}

function DefaultModal({
  headerMessage,
  bodyMessage,
  ButtonMessage,
  isOpen,
  closeFunc,
  onCloseEvent,
}) {
  const toggle = () => {
    closeFunc(false);
    if (onCloseEvent) {
      onCloseEvent();
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader tag="h6">{headerMessage}</ModalHeader>
      <ModalBody style={{ whiteSpace: 'break-spaces' }}>
        {bodyMessage}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={toggle}>
          {ButtonMessage}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

DefaultModal.propTypes = {
  headerMessage: PropTypes.string,
  bodyMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  ButtonMessage: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  closeFunc: PropTypes.func.isRequired,
  onCloseEvent: PropTypes.func,
};
DefaultModal.defaultProps = {
  headerMessage: '',
  bodyMessage: '',
  ButtonMessage: '확인',
  onCloseEvent: () => {},
};

export function ConfirmModal({
  isOpen,
  setIsOpen,
  headerMessage,
  bodyMessage,
  okButtonMessage,
  cancelButtonMessage,
  onOkButtonHandler,
  onCancelButtonHandler,
}) {
  return (
    <Modal isOpen={isOpen} toggle={() => setIsOpen(false)}>
      <ModalHeader tag="h6">{headerMessage}</ModalHeader>
      <ModalBody>{bodyMessage}</ModalBody>
      <ModalFooter>
        <Button onClick={onCancelButtonHandler}>{cancelButtonMessage}</Button>
        <Button color="danger" onClick={onOkButtonHandler}>
          {okButtonMessage}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
ConfirmModal.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  headerMessage: PropTypes.string,
  bodyMessage: PropTypes.any,
  okButtonMessage: PropTypes.string,
  cancelButtonMessage: PropTypes.string,
  onOkButtonHandler: PropTypes.func,
  onCancelButtonHandler: PropTypes.func,
};

export default DefaultModal;
