import React, {
  createContext,
  useCallback,
  useEffect,
  useLayoutEffect,
  useReducer,
} from 'react';
import PropTypes from 'prop-types';
// import { useHistory } from 'react-router-dom';
import DefaultModal from '../components/Modals';
import { authActionCreator } from './actions/authActions';
import { authReducer, initialState } from './reducers/authReducer';
import Axios from 'axios';
import { DEFAULT_PAGE_PATH } from '../utils/constants';
import { useHistory } from 'react-router-dom';

export const ExpiredModalContext = createContext();
export const AuthContext = createContext();

function ModalReducer(state, action) {
  switch (action.type) {
    case 'OPEN':
      return {
        visible: true,
        title: action.payload.title,
        body: action.payload.body,
        btnText: action.payload.btnText,
        to: action.payload.to,
      };
    case 'CLOSE':
      return {
        ...state,
        visible: false,
      };
    default:
      return state;
  }
}
export default function AuthProvider({ children }) {
  const history = useHistory();
  const [modalState, modalDispatch] = useReducer(ModalReducer, {
    visible: false,
    title: '',
    body: '',
    btnText: '',
    to: '',
  });
  const [store, stroeDispatch] = useReducer(authReducer, initialState);

  const showAuthModal = useCallback((title, body, btnText, to) => {
    modalDispatch({ type: 'OPEN', payload: { title, body, btnText, to } });
  }, []);

  const hideAuthModal = useCallback(() => {
    modalDispatch({ type: 'CLOSE' });
  }, []);

  const onCloseEvent = async () => {
    if (modalState.to !== DEFAULT_PAGE_PATH) {
      stroeDispatch(authActionCreator.Logout());
    } else {
      history.push(modalState.to);
    }
  };
  useLayoutEffect(() => {
    if (
      store.isLogin === true &&
      typeof store.userInfo?.access_token === 'string'
    ) {
      Axios.defaults.headers.common.Authorization = `Bearer ${store.userInfo.access_token}`;
      return () => {
        if (Axios.defaults.headers.common.Authorization) {
          delete Axios.defaults.headers.common.Authorization;
        }
      };
    }
  }, [store.isLogin, store.userInfo?.access_token]);

  useEffect(() => {
    if (store.isLogin) {
      const id = Axios.interceptors.response.use(
        (res) => {
          return res;
        },
        async (err) => {
          const originalConfig = { ...err.config };

          const isAuthUrl = (originalConfig?.url ?? '').startsWith('/auth');
          if (
            isAuthUrl === false &&
            err?.response &&
            err.response.status === 401
          ) {
            // Access Token was expired
            if (originalConfig._retry !== true) {
              originalConfig._retry = true;

              const rs = await Axios.get('/auth/access-token');

              const { access_token } = rs.data;
              stroeDispatch(authActionCreator.refreshAccessToken(access_token));
              const headers = originalConfig.headers
                ? originalConfig.headers
                : {};
              headers.Authorization = `Bearer ${access_token}`;
              originalConfig.headers = headers;

              return Axios(originalConfig);
            } else {
              showAuthModal(
                '인증 오류',
                '인증정보가 만료되었거나 잘못되었습니다.',
                '로그인 화면으로',
                '/login'
              );
            }
          }
          if (err?.response && err.response.status === 403) {
            showAuthModal(
              '접근권한 안내',
              '해당 게시판에 대한 권한이 없습니다.',
              '확인',
              DEFAULT_PAGE_PATH
            );
          }

          return Promise.reject(err);
        }
      );
      return () => {
        Axios.interceptors.response.eject(id);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.isLogin]);

  return (
    <ExpiredModalContext.Provider value={showAuthModal}>
      <AuthContext.Provider value={[store, stroeDispatch]}>
        <DefaultModal
          isOpen={modalState.visible}
          ButtonMessage={modalState.btnText}
          bodyMessage={modalState.body}
          headerMessage={modalState.title}
          closeFunc={hideAuthModal}
          onCloseEvent={onCloseEvent}
        />
        {children}
      </AuthContext.Provider>
    </ExpiredModalContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};
